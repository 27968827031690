import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.howTo || initialState;

export const selectHowTo = createSelector(
  [selectDomain],
  howToState => howToState,
);
