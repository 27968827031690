/**
 *
 * Asynchronously loads the component for AdminSubscribers
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const AdminSubscribers = lazyLoad(
   () => import('./index'),
   module => module.AdminSubscribers,
 );
 