import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  clientResources: [],
};

const clientResourcesSlice = createSlice({
  name: 'clientResources',
  initialState,
  reducers: {
    fetchClientResources() { },
    clientResourcesLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    clientResourcesSuccess(state, action) {
      state.isLoading = false;
      state.clientResources = action.payload;
      state.error = '';
    },
    clientResourcesFailure(state, action) {
      state.isLoading = false;
      state.clientResources = [];
      state.error = action.payload;
    },

    // Save client resources
    saveClientResource() { },
    clientResourceSaveLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    clientResourceSaveSuccess(state, action) {
      state.isLoading = false;
      state.clientResources.push(action.payload);
      state.error = '';
    },
    clientResourceSaveFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Update client resources
    updateClientResource() { },
    clientResourceUpdateLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    clientResourceUpdateSuccess(state, action) {

      const temp = state.clientResources.map(resource => {
        if (resource.id === action.payload.id) {
          return { ...resource, ...action.payload };
        }
        return resource;
      });

      state.isLoading = false;
      state.clientResources = temp;
      state.error = '';
    },
    clientResourceUpdateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Delete client resources
    deleteClientResource() { },
    clientResourceDeleteLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    clientResourceDeleteSuccess(state, action) {

      const temp = [...state.clientResources];

      const index = _.findIndex(temp, { id: action.payload.id });

      temp.splice(index, 1);

      state.isLoading = false;
      state.clientResources = temp;
      state.error = '';
    },
    clientResourceDeleteFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Clear client resources
    clearClientResources() { },
    clearClientResourcesSuccess(state, action) {
      state.clientResources = [];
    },

    uploadMedia() { },
    uploadMediaLoading(state) {
      state.isLoading = true;
    },
    uploadMediaSuccess(state, action) {
      state.isLoading = false;
    },
    uploadMediaFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteMedia() { },
    deleteMediaLoading(state) {
      state.isLoading = true;
    },
    deleteMediaSuccess(state, action) {
      state.isLoading = false;

      const clientResources = state.clientResources.map(resource => {

        const contents = resource.contents.map(content => ({ ...content }));

        if (resource.id === action.payload.resourceId) {
          const index = _.findIndex(contents, function (item) {
            return _.findIndex(item.files, { id: action.payload.mediaId }) !== -1;
          });
          
          if (index !== -1) {
            const newFiles = contents[index].files.filter(item => item.id !== action.payload.mediaId);
            console.log(newFiles);
            contents[index].files = newFiles;
          }
        }

        return { ...resource, contents };
      });

      state.clientResources = clientResources;

    },
    deleteMediaFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = clientResourcesSlice;
