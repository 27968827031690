/**
 *
 * Asynchronously loads the component for WorkManagement
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const AdminReportSection = lazyLoad(
  () => import('./index'),
  module => module.AdminReportSection,
);
