/**
 *
 * Asynchronously loads the component for AdminClientResources
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const AdminClientResources = lazyLoad(
  () => import('./index'),
  module => module.AdminClientResources,
);
