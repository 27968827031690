/**
 *
 * Asynchronously loads the component for ContractDetails
 *
 */

 import { lazyLoad } from '../../../../utils/loadable';

 export const ContractDetails = lazyLoad(
   () => import('./index'),
   module => module.ContractDetails,
 );
 