import _ from 'lodash';
import moment from 'moment-timezone';
import userService from './userService';

export const loginUrl = '/login';
export const otpVerificationPageUrl = '/verify-otp';

export const isAuthenticated = async () => {
    const token = await userService.getUserToken('access');
    if (_.isEmpty(token)) {
        userService.removeUserToken('access');
        userService.removeUserToken('refresh');
        userService.removeUser();
        return false;
    }

    const expiry = moment(token.expires);
    const now = moment();

    if (expiry.isBefore(now)) {
        userService.removeUserToken('access');
        userService.removeUserToken('refresh');
        userService.removeUser();
        return false;
    }

    return true;
};

export const isAdmin = async () => {
    const user = await userService.getUser();
    if (!user) return false;

    return user.isAdmin;
};

export const hasPermissionsToViewPage = (user, pageKey, isAdminRoute) => {
    if (pageKey) {
        if (!user.isAdmin && user.parent && user.parent.length > 0 && user.permissions.indexOf(pageKey) === -1) {
            return false;
        }

        if (user.isAdmin && user.parent && user.parent.length > 0 && (user.permissions.indexOf(pageKey) === -1 || _.findIndex(user.permissions, (key) => key.includes(pageKey)) === -1) && isAdminRoute) {
            return false;
        }
        if (user.isGuestUser && pageKey !== 'WORKFLOW') {
            return false;
        }

    }
    return true;
}

const authService = {
    isAuthenticated,
    loginUrl,
    isAdmin,
    otpVerificationPageUrl,
    hasPermissionsToViewPage,
};

export default authService;
