import { Modal } from "rsuite"

const VideoPopup = (props) => {

    return (
        <>
            <Modal show={props.visibility} onHide={props.onClose}>
                <Modal.Header>
                    <Modal.Title>Modal Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.video && <iframe
                            width="560"
                            height="315"
                            src={props.video}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                        </iframe>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

VideoPopup.defaultProps = {
    onClose: () => { },
    visibility: false,
};

export default VideoPopup;