export const getAvatarName = (name) => {
    return name.split(" ").map((n) => n[0]).join("").toUpperCase();
};

export const stripTags = (str) => {
    return str.replace(/<[^>]+>|&nbsp;/g, '');
};

export const parseHtmlReBuildContent = (htmlString) => {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(htmlString, "text/html");
    const headings = doc.querySelectorAll('a');
    headings.forEach(elem => {
        elem.setAttribute('target', '_blank');
    });
    // console.log("new html =" ,doc.body.innerHTML)
    return doc.body.innerHTML;
};