/**
 *
 * Asynchronously loads the component for AdminSettings
 *
 */
import { lazyLoad } from '../../../utils/loadable';

export const AdminSettings = lazyLoad(
  () => import('./index'),
  module => module.AdminSettings,
);
