import { Uploader } from 'rsuite';
import './styles.less';
import { parseHtmlReBuildContent } from "../../../utils/stringUtility";

const FileInput = (props) => {
    const { inputOptions, noLabel, label, children,labelStyle } = props;
    return (
        <div
            className={
                `form-input-group
                ${props.inlineLabel ? ' label-inline' : ''}
                ${props.stackedLabel ? ' label-stacked' : ''}
                ${typeof props.stackedLabel === 'undefined' && typeof props.inlineLabel === 'undefined' ? ' label-inline' : ''}`
            }
        >
           
            {!noLabel && <label style={labelStyle} className="label-inline-display"><div dangerouslySetInnerHTML={{ __html: parseHtmlReBuildContent(label) }} /> {(props.isMandatory && (props.isMandatory == "true" || props.isMandatory == true)) && <span style={{ color: "#F62626" }}>*</span>}</label>}
            <Uploader {...inputOptions} children={children} />
        </div>
    );

};

export default FileInput;