import storageService from './localstorageService';
import _ from 'lodash';

export const applyTheme = async (theme = 'theme') => {
    const themeData = await storageService.getItem(theme);
    if (_.isEmpty(themeData)) {
        return;
    }

    const themeCss = `
        body, html {
        --primary: ${themeData.primaryColor === '' ? '#032540': themeData.primaryColor};
        --secondary: ${themeData.secondaryColor === '' ? '#37C5EE' : themeData.secondaryColor };
        --text-color: ${themeData.textColor === '' ? '#ffffff' : themeData.textColor };
        }
    `;
    
    let style = document.getElementById('theming-style');
    
    if (style) {
        style.remove();
        style = document.createElement('style');
    } else {
        style = document.createElement('style');
    }

    const head = document.head;
    
    style.id = 'theming-style';
    head.appendChild(style);

    style.appendChild(document.createTextNode(themeCss));

};

export const saveTheme = async (theme, values) => {
    await storageService.setItem(theme, values);
};

export const getTheme = async (theme) => {
    return await storageService.getItem(theme);
};

export const removeTheme = async () => {
    await storageService.removeItem('theme');
};