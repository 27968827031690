/**
 *
 * Asynchronously loads the component for AdminRequestLegalSupport
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const AdminRequestLegalSupport = lazyLoad(
  () => import('./index'),
  module => module.AdminRequestLegalSupport,
);
