import { useCallback, useEffect, useState } from "react";
import { Button, Col, Icon, IconButton, Modal, Row, Table, Radio, FlexboxGrid } from "rsuite";
import SquareButton from "../SquareButton";
import TextInput from "../TextInput";
import _ from "lodash";
import Toast from "../../../services/toastService";

const { Column, Cell, HeaderCell } = Table;

const SwitchSubscriberModal = (props) => {

  const [title, setTitle] = useState("");
  const [selectedParent, setSelectedParent] = useState('')

  useEffect(() => {
    setTitle(props.title)
    setSelectedParent(props.currentParent)
  }, [props])

  const onSave = () => {
    // if (!title || title === "") {
    //   Toast.error("Please add Template Name");
    //   return;
    // }
    props.onSave(selectedParent);
  };

  const onClose = () => {
    props.onClose();
  }

  return (
    <Modal size="md" show={props.isVisible} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>{"Switch Subscriber"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div >
          {
            (props.subscribers && props.subscribers.length < 2) ?
              <div>You are only associated with one LightHub Account.</div>
              :
              // <div>Show subscriber list</div>
              <Table
                // affixHorizontalScrollbar
                data={props.subscribers}

                className="admin-list-table"
                style={{ marginTop: 10, fontSize: 14 }}
              >
                <Column flexGrow={1} verticalAlign="middle" align="left">
                  <HeaderCell style={{ fontSize: 14 }}>Subscribers</HeaderCell>
                  <Cell>
                  {
                      (rowData, index) => {
                        return rowData.organisationName ? rowData.organisationName : rowData.name
                      }
                  }
                  </Cell>
                </Column>

                <Column flexGrow={1} verticalAlign="middle" align="left">
                  <HeaderCell style={{ fontSize: 14 }}>Current Subscriber</HeaderCell>
                  <Cell className="">
                    {
                      (rowData, index) => {
                        return (
                          <span className="d-flex" >
                            <Radio
                              checked={rowData.id === selectedParent}
                              onChange={(value, checked, event) => {
                                if(checked){
                                  setSelectedParent(rowData.id)
                                }
                               }}
                            />
                          </span>
                        );
                      }
                    }
                  </Cell>
                </Column>
              </Table>
          }
          {/* <SquareButton onClick={onClose} secondary className="mr2">
            Cancel
          </SquareButton> */}
          <Col flexGrow={1} verticalAlign="middle" align="right">
            <SquareButton onClick={onSave}>Save</SquareButton>
          </Col>

        </div>
        {/* <Modal.Header>
          <Modal.Title>{"Set Default Subscriber"}</Modal.Title>
        </Modal.Header>
        <div >
          {
            <Table
              // affixHorizontalScrollbar
              data={props.subscribers}

              className="admin-list-table"
              style={{ marginTop: 10, fontSize: 14 }}
            >
              <Column flexGrow={1} verticalAlign="middle" align="left">
                <HeaderCell style={{ fontSize: 14 }}>Subscribers</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column flexGrow={1} verticalAlign="middle" align="left">
                <HeaderCell style={{ fontSize: 14 }}>Current Default Subscriber</HeaderCell>
                <Cell className="">
                  {
                    (rowData, index) => {
                      return (
                        <span className="d-flex" >
                          <Radio
                            checked={rowData.isActive}
                            onChange={() => { }}
                          />
                        </span>
                      );
                    }
                  }
                </Cell>
              </Column>
            </Table>
          }
        </div> */}
      </Modal.Body>
      {/* <Modal.Footer>
        
        <SquareButton onClick={onSave}>Save</SquareButton>
      </Modal.Footer> */}
    </Modal>
  );
};

SwitchSubscriberModal.defaultProps = {
  //title: "Template Name...",
  isVisible: true,
  onClose: () => { },
  onSave: (role) => { },
};

export default SwitchSubscriberModal;
