import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
    isLoading: false,
    success: false,
    error: '',
    user: {},
    users: [],
    assignmentUsers: [],
    notifications: [],
    notificationCount: 0,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // change password
        changePassword() { },
        changePasswordLoading(state, action) {
            state.loading = true;
        },
        changePasswordSuccess(state, action) {
            state.loading = false;
        },
        changePasswordFailure(state, action) {
            state.loading = false;
        },

        // fetch Users
        fetchUsers() { },
        usersLoading(state) {
            state.isLoading = true;
            state.error = '';
        },
        usersSuccess(state, action) {
            state.isLoading = false;
            state.users = action.payload;
            state.error = '';
        },
        usersFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // fetch Users
        fetchUserNotifications() { },
        userNotificationsLoading(state) {
            state.isLoading = true;
            state.error = '';
        },
        userNotificationsSuccess(state, action) {
            state.isLoading = false;
            state.notifications = (action.payload.page === 1) ? action.payload.results : [...state.notifications, ...action.payload.results];
            state.notificationCount = action.payload.totalUnReadActivity;
            state.error = '';
        },
        userNotificationsFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // fetch legal request users
        fetchLegalRequestUsers() { },
        fetchLegalRequestUsersLoading(state, action) {
            state.isLoading = true;
        },
        fetchLegalRequestUsersSuccess(state, action) {
            state.isLoading = false;
            state.assignmentUsers = action.payload;
        },
        fetchLegalRequestUsersFailure(state, action) {
            state.isLoading = false;
        },

        // mark notification read
        markNotificationRead() { },
        markNotificationReadLoading(state, action) {
            state.isLoading = true;
        },
        markNotificationReadSuccess(state, action) {
            state.isLoading = false;
            const temp = [...state.notifications];

            const index = _.findIndex(temp, { _id: action.payload });

            if (index !== -1) {
                temp[index].readStatus = true;
            }

            state.notifications = temp;
        },
        markNotificationReadFailure(state, action) {
            state.isLoading = false;
        },
        fetchSubscriberList() {},
        fetchSubscriberListSuccess(state, action) {
            state.loading = false
        },
        selectSubscriber() {},
        selectSubscriberLoading(state, action) {
            state.isLoading = true;
        },
        selectSubscriberSuccess(state, action) {
            state.isLoading = false;
        },
        selectSubscriberFailure(state, action) {
            state.isLoading = false;
        }
    },
});

export const { actions, reducer, name: sliceKey } = userSlice;
