import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import userService from '../../../services/userService';
import { isAuthenticated } from '../../../services/authService';
import { getTheme, removeTheme } from '../../../services/themeService';
import { delayedRedirect } from '../../../utils/response';

// startup saga
export function* startup(action) {
  yield put(actions.startupLoading());

  const isUserAuthenticated = yield call(isAuthenticated);
  const userDetails = yield call(userService.getUser);
  const themeDetails = yield call(getTheme, 'theme');

  if (!isUserAuthenticated && userDetails) {
    yield call(userService.removeUserToken, 'access');
    yield call(userService.removeUserToken, 'refresh');
    yield call(userService.removeUser);
    yield call(removeTheme);
    yield call(userService.deleteIsOTPSentOnPageLoad);
    yield call(userService.deleteIsOTPVerification);

    yield delayedRedirect('/login', 1000);
  } else if (!isUserAuthenticated && themeDetails) {
    yield call(removeTheme);
    yield call(userService.deleteIsOTPSentOnPageLoad);
    yield call(userService.deleteIsOTPVerification);
    yield delayedRedirect('/login', 1000);
  }

  yield put(actions.startupSuccess());

}

export function* startupSaga() {
  yield takeLatest(actions.startup.type, startup);
}
