/**
 *
 * Asynchronously loads the component for AdminManagementCenter
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const AdminManagementCenter = lazyLoad(
  () => import('./index'),
  module => module.AdminManagementCenter,
);
