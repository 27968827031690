import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  form: {},
  forms: {},
  clientForms : []
};

const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    // fetch all forms
    fetchForms() { },
    formsLoading(state, action) {
      state.isLoading = true;
    },
    formsSuccess(state, action) {
      state.isLoading = false;
      state.forms = action.payload;
      state.error = '';
    },
    formsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // fetch on form by id
    fetchForm() { },
    formLoading(state, action) {
      state.isLoading = true;
    },
    formSuccess(state, action) {
      state.isLoading = false;
      state.form = action.payload;
      state.error = '';
    },
    formFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },


    // fetch on form for client
    fetchClientsForm() { },
    fetchClientsFormLoading(state, action) {
      state.isLoading = true;
    },
    fetchClientsFormSuccess(state, action) {
      state.isLoading = false;
      state.clientForms = action.payload;
      state.error = '';
    },
    fetchClientsFormFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // save form
    saveForm() { },
    saveFormLoading(state, action) {
      state.isLoading = true;
    },
    saveFormSuccess(state, action) {
      state.isLoading = false;
    },
    saveFormFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // update form
    updateForm() { },
    updateFormLoading(state, action) {
      state.isLoading = true;
    },
    updateFormSuccess(state, action) {
      state.isLoading = false;
    },
    updateFormFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // update form
    deleteForm() { },
    deleteFormLoading(state, action) {
      state.isLoading = true;
    },
    deleteFormSuccess(state, action) {
      const tempForms = [...state.forms.results];

      const index = _.findIndex(tempForms, { id: action.payload.id });
      if (index !== -1) {
        tempForms.splice(index, 1);
        Object.assign(state.forms, { results: tempForms });
      }
      state.isLoading = false;
    },
    deleteFormFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = formBuilderSlice;
