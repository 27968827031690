/**
 *
 * Asynchronously loads the component for VerifyOTP
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const VerifyOTP = lazyLoad(
   () => import('./index'),
   module => module.VerifyOTP,
 );
 