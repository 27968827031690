import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.startup || initialState;

export const selectStartup = createSelector(
  [selectDomain],
  selectStartupState => selectStartupState,
);
