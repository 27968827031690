import * as OriginalLoader from "rsuite/lib/Loader";

const Loader = () => {
    return (
        <OriginalLoader
            backdrop
            size="lg"
            content="Loading"
            vertical
        />
    );
};

export default Loader;