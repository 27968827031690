import { Link } from "react-router-dom";
import { Nav, Sidebar, Sidenav } from "rsuite";
import menuBars from './../../../SVGIcons/svgs/menu_bars.svg';
import IconDashboard from './../../../SVGIcons/SVG/components/IconDashboard';
import IconManagementCenter from './../../../SVGIcons/SVG/components/IconManagementCenter';
import IconRequestLegalSupport from './../../../SVGIcons/SVG/components/IconRequestLegalSupport';
import IconClientResources from './../../../SVGIcons/SVG/components/IconClientResources';
import IconContractManagement from '../../../SVGIcons/SVG/components/IconContractManagement';
import IconWisdom from "../../../SVGIcons/SVG/components/IconWisdom";
import IconFolder from "../../../SVGIcons/SVG/components/IconFolder";
import IconQuestion from './../../../SVGIcons/SVG/components/IconQuestion';
import IconTemplate from "../../../SVGIcons/SVG/components/IconTemplate";
import IconSignedContract from "../../../SVGIcons/SVG/components/IconSignedContract";
import React from "react";
import _ from "lodash";
import IconWorkflowManagement from "../../../SVGIcons/SVG/components/IconWorkflowManagement";
import { hasPermissionsToViewPage } from "../../../../../services/authService";

const LinkWrapper = React.forwardRef((parentProps, ref) => {
    const { href, as, history, to, children, ...rest } = parentProps;

    return (
        <a ref={ref} as={as} {...rest} onClick={() => history.push(to)}>
            {children}
        </a>
    );
});

const NavLink = props => <Nav.Item componentClass={LinkWrapper} {...props} />;

const AdminSidebar = (props) => {
    const { expand, location, history } = props;
    return (
        <Sidebar
            style={{ display: 'flex', flexDirection: 'column' }}
            width={expand ? 240 : 56}
            collapsible
        >
            <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
                <Sidenav.Header>
                    <div className="sidenav-header">
                        <Link to={'/'}>
                            {!expand || <span> <img src={!_.isEmpty(props.logo) ? props.logo : "/logo.png"} alt="LightHouseHub" /></span>}
                        </Link>
                        <img src={menuBars} style={{ verticalAlign: 1, float: 'right', paddingTop: 10 }} onClick={props.handleToggle} alt="menu_bars" />
                    </div>
                </Sidenav.Header>
                <Sidenav.Body className="sidenav-body">
                    <Nav>
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_MANAGEMENT_CENTER') && <NavLink eventKey="1" to="/admin/management-center" history={history} active={location.pathname === '/admin/management-center'} icon={<IconManagementCenter className="rs-icon" />}>
                            Management Center
                        </NavLink>}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_SUBSCRIBER') && <NavLink eventKey="3" to="/admin/subscribers" history={history} active={location.pathname === '/admin/subscribers'} icon={<IconClientResources className="rs-icon" />}>
                            Subscribers
                        </NavLink>}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_DASHBOARD') && <NavLink eventKey="2" to="/" history={history} active={location.pathname === '/'} icon={<IconManagementCenter className="rs-icon" />}>
                            Workflow
                        </NavLink>}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_ADD_SIGNED_CONTRACT') && <NavLink eventKey="11" to="/admin/add_signed_contract" history={history} active={location.pathname === '/admin/add_signed_contract'} icon={<IconSignedContract className="rs-icon" />}>
                            Add Signed Contract
                        </NavLink>}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_REQUEST_LEGAL_SUPPORT') && <NavLink eventKey="4" to="/admin/request-legal-support" history={history} active={location.pathname === '/admin/request-legal-support'} icon={<IconRequestLegalSupport className="rs-icon" />}>
                            Request Support
                        </NavLink>} */}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "ADMIN_REPORTING") && (
                            <NavLink eventKey="5" to="/admin/reporting" history={history} active={location.pathname === "/admin/reporting"} icon={<IconFolder className="rs-icon" style={{ width: 20, marginLeft: 3 }} />} >  Reporting  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_CLIENT_RESOURCES') && <NavLink eventKey="6" to="/admin/client-resources" history={history} active={location.pathname === '/admin/client-resources'} icon={<IconClientResources className="rs-icon" />} >
                            Resources
                        </NavLink>}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_CLIENT_RESOURCES') && ( */}
                        <NavLink eventKey="7" to="/admin/my-learned-friend" history={history} active={location.pathname === "/admin/my-learned-friend"} icon={<IconWisdom className="rs-icon" />}   > My Learned Friend  </NavLink>
                        {/* )} */}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_HOW_TO') && <NavLink eventKey="8" to="/admin/how-to" history={history} active={location.pathname === '/admin/how-to'} icon={<IconQuestion className="rs-icon" />}>
                            How to use LightHub
                        </NavLink>}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_CATEGORY_WORKFLOW') && <NavLink eventKey="9" to="/admin/category-workflow" history={history} active={location.pathname === '/admin/category-workflow'} icon={<IconClientResources className="rs-icon" />} >
                            Matter Admin
                        </NavLink>} */}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_CONTRACT_MANAGEMENT') && <NavLink eventKey="6" to="/admin/contract-management" history={history} active={location.pathname === '/admin/contract-management'} icon={<IconContractManagement className="rs-icon" />}>
                            Contract Management
                        </NavLink>} */}
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "MATTER_ADMIN") && (
                            <NavLink eventKey="4" to="/matter-admin" history={history} active={location.pathname === "/matter-admin"} icon={<IconRequestLegalSupport className="rs-icon" />}  >Matter Admin  </NavLink>
                        )}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_WORKFLOW_MANAGEMENT') && <NavLink eventKey="10" to="/admin/integrations" history={history} active={location.pathname === '/admin/integrations'} icon={<IconWorkflowManagement className="rs-icon" />}>
                            Integrations
                        </NavLink>} */}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'ADMIN_MANAGE_TEMPLATE') && <NavLink eventKey="12" to="/admin/templates" history={history} active={location.pathname === '/admin/templates'} icon={<IconTemplate className="rs-icon" />}>
                            Templates
                        </NavLink>}


                        {/* <NavLink eventKey="9" to="/admin/events-log" history={history} active={location.pathname === '/admin/events-log'} icon={<img className="rs-icon" src={knowledge} alt="Event Log" />}>
                    Event Log
                  </NavLink> */}

                        {/* <NavLink eventKey="10" to="/admin/action-history" history={history} active={location.pathname === '/admin/action-history'} icon={<img className="rs-icon" src={knowledge} alt="Action History" />}>
                    Admin Action History
                  </NavLink> */}
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </Sidebar>
    );
};

export default AdminSidebar;