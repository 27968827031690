import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Loader } from 'rsuite';

export const SHOW = 'loading/SHOW';
export const HIDE = 'loading/HIDE';
export const RESET = 'loading/RESET';

const defaultTypeSuffixes = ['PENDING', 'FULFILLED', 'REJECTED'];

export default function loadingMiddleware(config) {
  const promiseTypeSuffixes = config.promiseTypeSuffixes || defaultTypeSuffixes;
  return ({ dispatch }) => next => action => {
    if (action.type) {
      const [PENDING, FULFILLED, REJECTED] = promiseTypeSuffixes;

      const isPending = new RegExp(`${PENDING}$`, 'g');
      const isFulfilled = new RegExp(`${FULFILLED}$`, 'g');
      const isRejected = new RegExp(`${REJECTED}$`, 'g');

      if (action.type.match(isPending)) {
        dispatch(showLoading());
      } else if (
        action.type.match(isFulfilled) ||
        action.type.match(isRejected)
      ) {
        dispatch(hideLoading());
      }
    }

    return next(action);
  };
}

export function showLoading() {
  return {
    type: SHOW,
  };
}

export function hideLoading() {
  return {
    type: HIDE,
  };
}

export function loadingReducer(state = { isLoading: false }, action) {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

const selectDomain = (state) => state.loader || { isLoading: false };

export const selectLoader = createSelector(
  [selectDomain],
  loaderState => loaderState,
);

export const PageLoader = (props) => {

  const loader = useSelector(selectLoader);
  // console.log('loader', loader);
  if (loader.isLoading)
    return (
      <Loader
        center
        backdrop
        content="Please wait..."
        vertical size="lg"
        style={{ zIndex: 9999 }}
      />
    );
  else return null;

};
