import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  howTo: {},
  howTos: [],
};

const howToSlice = createSlice({
  name: 'howTo',
  initialState,
  reducers: {
    // fetch all howTos
    fetchHowTos() { },
    howTosLoading(state, action) {
      state.isLoading = true;
    },
    howTosSuccess(state, action) {
      state.isLoading = false;
      state.howTos = action.payload;
      state.error = '';
    },
    howTosFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // fetch on howTo by id
    fetchHowTo() { },
    howToLoading(state, action) {
      state.isLoading = true;
    },
    howToSuccess(state, action) {
      state.isLoading = false;
      state.howTo = action.payload;
      state.error = '';
    },
    howToFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // fetch on howTo by id
    downloadHowTo() { },
    downloadHowToLoading(state, action) {
      state.isLoading = true;
    },
    downloadHowToSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    downloadHowToFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // save howTo
    saveHowTo() { },
    saveHowToLoading(state, action) {
      state.isLoading = true;
    },
    saveHowToSuccess(state, action) {
      state.isLoading = false;
    },
    saveHowToFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // update howTo
    updateHowTo() { },
    updateHowToLoading(state, action) {
      state.isLoading = true;
    },
    updateHowToSuccess(state, action) {
      state.isLoading = false;
    },
    updateHowToFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // update howTo
    deleteHowTo() { },
    deleteHowToLoading(state, action) {
      state.isLoading = true;
    },
    deleteHowToSuccess(state, action) {
      const tempHowTos = [...state.howTos.results];

      const index = _.findIndex(tempHowTos, { id: action.payload.id });
      if (index !== -1) {
        tempHowTos.splice(index, 1);
        Object.assign(state.howTos, { results: tempHowTos });
      }
      state.isLoading = false;
    },
    deleteHowToFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = howToSlice;
