/**
 *
 * Asynchronously loads the component for Dashboard
 *
 */

import { lazyLoad } from "../../../utils/loadable";

export const DashboardHome = lazyLoad(
  () => import("./index"),
  (module) => module.DashboardHome
);
