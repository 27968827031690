import { useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import AdminHowToForm from "../../components/AdminHowToForm";
import AdminHowToList from "../../components/AdminHowToList";
import Layout from "../../components/Layout";

export const AdminHowTo = (props) => {

    const [pageState, setPageState] = useState('');

    useLayoutEffect(() => {
        if (props.location.pathname.includes('add')) {
            setPageState('add');
        }

        if (props.match.params.howToId) {
            setPageState('edit');
        }

        if (!props.location.pathname.includes('add') && !props.match.params.howToId) {
            setPageState('list');
        }
    }, []);

    return (
        <Layout location={props.location} history={props.history}>
            <Helmet>
                <title>Admin How To</title>
            </Helmet>

            {pageState === 'add' ? <AdminHowToForm /> : null}

            {pageState === 'edit' ? <AdminHowToForm howToId={props.match.params.howToId} /> : null}

            {pageState === 'list' ? <AdminHowToList /> : null}
        </Layout>
    );
}