/**
 *
 * Asynchronously loads the component for ClientResources
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const ClientResources = lazyLoad(
  () => import('./index'),
  module => module.ClientResources,
);
