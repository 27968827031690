import './styles.less';

const SquareButton = (props) => {
    const {secondary, informative, primaryAlt, className, fullWidth, informativeBordered, ...rest} = props;
    return (
        <button
            {...rest}
            className={`input-form-btn round-rect-btn${secondary ? ' secondary' : ''}${informative ? ' informative' : informativeBordered ? ' informative-bordered' : ''}${primaryAlt ? ' primary-alt-text' : ''}${fullWidth ? ' fullwidth' : ''} ${className || ''}`}
        />
    );
}

export default SquareButton;