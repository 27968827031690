import { Notification } from "rsuite"

const success = (description) => {
    Notification.success({
        title: "Success",
        description,
    });
};

const error = (description) => {
    Notification.error({
        title: "Error",
        description,
    });
}

const info = (description) => {
    Notification.info({
        title: "Info",
        description,
    });
}

const warning = (description) => {
    Notification.info({
        title: "Warning",
        description,
    });
}

const Toast = {
    success,
    error,
    info,
    warning,
};

export default Toast;
