/**
 *
 * Asynchronously loads the component for AdminClients
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const AdminClients = lazyLoad(
  () => import('./index'),
  module => module.AdminClients,
);
