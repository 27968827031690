import { useEffect, useState } from "react";
import { Col, FlexboxGrid, Panel, Row } from "rsuite";
import SquareButton from "../SquareButton";
import TextInput from "../TextInput";
import { reducer, sliceKey, actions } from './../../shared-redux/HowTo/slice';
import { selectHowTo } from './../../shared-redux/HowTo/selectors';
import { howToSaga } from './../../shared-redux/HowTo/saga';
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import _ from "lodash";
import SelectInputNoSearch from "../SelectInputNoSearch";
import FileInput from "../FileInput";
import { redirect } from "../../../utils/response";
import Toast from "../../../services/toastService";

const AdminHowToForm = (props) => {
    const { howToId } = props;
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: howToSaga });

    const accepted = {
        DOC: '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        PDF: 'application/pdf',
        VIDEO: '.jpg,.png',
    };

    const { howTo } = useSelector(selectHowTo);

    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [contentType, setContentType] = useState('');
    const [file, setFile] = useState(null);
    const [videoLink, setVideoLink] = useState(null);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (howToId) {
            dispatch({
                type: actions.fetchHowTo.type,
                payload: { id: howToId },
            });
        }
    }, [howToId]);

    useEffect(() => {
        if (!_.isEmpty(howTo)) {
            setTitle(howTo.title);
            setVideoLink(!_.isEmpty(howTo.videoLink) ? howTo.videoLink : null);
            setContentType(howTo.contentType);
            setFileList([{ name: howTo.file.fileName }])
        }
    }, [howTo]);

    const submitForm = () => {

        const formData = new FormData();

        formData.append('title', title);
        formData.append('contentType', contentType);
        formData.append('videoLink', videoLink);

        if (!_.isEmpty(howTo)) {
            if (file) {
                formData.append('attachment', file.blobFile);
            }
            dispatch({
                type: actions.updateHowTo.type,
                payload: {
                    data: formData,
                    id: howTo.id,
                },
            });
        } else {
            if (!file) {
                Toast.error('Please upload file');
                return;
            }
            formData.append('attachment', file.blobFile);
            dispatch({
                type: actions.saveHowTo.type,
                payload: {
                    data: formData,
                },
            });
        }
    };

    const onFileDragOrSelect = (files) => {
        if (files.length === 1) {
            setFileList(files);
            setFile(files[0]);
        } else {
            setFileList([files[1]]);
            setFile(files[1]);
        }
    };

    const resetForm = () => {
        redirect('/admin/how-to');
    };

    return (
        <Panel collapsible header="Add Content" className="page-content-panel" defaultExpanded>
            <Row className="content-row">
                <Col md={24} className="mb2">
                    <TextInput label="Title" stackedLabel inputOptions={{
                        onChange: (event) => { setTitle(event.target.value) },
                        value: title
                    }} />
                </Col>
                <Col md={24} className="mb2">
                    <SelectInputNoSearch label="Content Type" stackedLabel inputOptions={{
                        onSelect: (value) => { setContentType(value) },
                        onClean: () => setContentType(''),
                        value: contentType,
                        creatable: false,
                        data: [
                            { label: 'PDF', value: 'PDF' },
                            { label: 'Video', value: 'VIDEO' },
                            { label: 'DOC', value: 'DOC' }
                        ],
                        placeholder: "Select One",
                    }} />
                </Col>
                <Col md={24} className="mb2">
                    <FileInput
                        stackedLabel
                        label="Upload File"
                        inputOptions={{
                            autoUpload: false,
                            multiple: false,
                            onChange: (files) => onFileDragOrSelect(files),
                            accept: accepted[contentType],
                            fileList,
                        }}
                    />
                </Col>

                {
                    contentType !== 'VIDEO' ||
                    <Col md={24} className="mb2">
                        <TextInput label="Youtube Video Embed Link" stackedLabel inputOptions={{
                            onChange: (event) => setVideoLink(event.target.value),
                            value: videoLink,
                        }} />
                    </Col>
                }

            </Row>

            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24} md={12} componentClass={Col} className="ml-auto flex-align-right p-0">
                    <SquareButton className="mr2" onClick={submitForm}>Submit</SquareButton>
                    <SquareButton secondary onClick={resetForm}>Discard</SquareButton>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
}

export default AdminHowToForm;