import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.user || initialState;

export const selectUser = createSelector(
  [selectDomain],
  selectUserState => selectUserState,
);
