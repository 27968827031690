import * as React from "react";
import { useState } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "rsuite/dist/styles/rsuite-default.min.css";
import "basscss/css/basscss.min.css";
import "./styles/global-styles.less";
import { HelmetProvider } from "react-helmet-async";

import { Dashboard } from "./containers/Dashboard/Loadable";
import { DashboardHome } from "./containers/DashboardHome/Loadable";
import { RequestLegalSupportDynamic } from "./containers/RequestLegalSupportDynamic/Loadable";
import { AdminRequestLegalSupport } from "./containers/AdminRequestLegalSupport/Loadable";
import { HowTo } from "./containers/HowTo/Loadable";
// import { ManagementCenter } from './containers/ManagementCenter/Loadable';
import { Profile } from "./containers/Profile/Loadable";
import { Settings } from "./containers/Settings/Loadable";
import { WorkManagement } from "./containers/WorkManagement/Loadable";
import { WorkManagementLiberty } from "./containers/WorkManagementLiberty/Loadable";
import { ContractStorage } from "./containers/ContractStorage/Loadable";
import { DataReporting } from "./containers/DataReporting/Loadable";
import { SignedContracts } from "./containers/SignedContracts/Loadable";
import { MyLearnedFriend } from "./containers/MyLearnedFriend/Loadable";
import { applyTheme } from "./../services/themeService";
import { ClientResources } from "./containers/ClientResources/Loadable";
import { StandardContractTemplates } from "./containers/StandardContractTemplates/Loadable";
// Admin Imports
import { AdminClients } from "./containers/AdminClients/Loadable";
// import { AdminEventsLog } from './containers/AdminEventsLog/Loadable';
// import { AdminActionHistory } from './containers/AdminActionHistory/Loadable';
import { AdminManagementCenter } from "./containers/AdminManagementCenter/Loadable";
import { AdminClientResources } from "./containers/AdminClientResources/Loadable";
import { AdminReportSection } from "./containers/AdminReportSection/Loadable";
import { AdminProfile } from "./containers/AdminProfile/Loadable";
import { AdminSettings } from "./containers/AdminSettings/Loadable";
import { AdminLearnedFriend } from "./containers/AdminLearnedFriend/Loadable";
import { AdminContracts } from "./containers/AdminContracts/Loadable";
import { AdminCategoryWorkflow } from "./containers/AdminCategoryWorkflow/Loadable";
import { AdminHowTo } from "./containers/AdminHowTo";
import { LoginRegistration } from "./containers/LoginRegistration/Loadable";
import { CompleteSignup } from "./containers/CompleteSignup/Loadable";
import { EmailVerification } from "./containers/EmailVerification/Loadable";
import { CompleteCustomization } from "./containers/CompleteCustomization/Loadable";
import { NotFound } from "./containers/NotFound/Loadable";
import Auth from "./components/Auth";
import Loader from "./components/Loader";
import { PasswordReset } from "./containers/PasswordReset/Loadable";
import { Questionnaire } from "./containers/CE/Questionnaire/Loadable";
import { VerifyOTP } from "./containers/VerifyOTP/Loadable";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { reducer, sliceKey } from "./shared-redux/Auth/slice";
import { authSaga } from "./shared-redux/Auth/saga";
import { AdminSignedContract } from "./containers/AdminAddSignedContract/Loadable";

import {
    actions,
    reducer as startupReducer,
    sliceKey as startupSliceKey,
} from "./shared-redux/Startup/slice";
import { startupSaga } from "./shared-redux/Startup/saga";
import { selectStartup } from "./shared-redux/Startup/selectors";
import { List } from "./containers/CE/List/Loadable";
import { ContractDetails } from "./containers/CE/ContractDetails/Loadable";
import { AdminSubscribers } from "./containers/AdminSubscribers/Loadable";
import { AdminTemplates } from "./containers/AdminTemplates/Loadable";
import { NoPermission } from "./containers/NoPermission/Loadable";
import { useDispatch, useSelector } from "react-redux";
import { AdminWorkManagement } from "./containers/AdminWorkManagement/Loadable";
import { Contracts } from "./containers/Contracts/Loadable";
import { PageLoader } from "../services/LoaderService";
import { AdminFormBuilder } from "./containers/AdminFormBuilder/Loadable";
import { isAdmin } from '../services/authService';
import { ManageTemplate } from "./containers/ManageTemplate/Loadable";
import { ContactData } from './containers/ContractData/Loadable'
import AuthCheckProvider from "./components/AuthCheckProvider";

function App() {
    useInjectReducer({ key: startupSliceKey, reducer: startupReducer });
    useInjectSaga({ key: startupSliceKey, saga: startupSaga });

    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: authSaga });

    const dispatch = useDispatch();

    // const location = useLocation();

    const { isLoading } = useSelector(selectStartup);

    const [isUserAdmin, setUserIsAdmin] = useState(false)

    React.useLayoutEffect(() => {
        dispatch({
            type: actions.startup.type,
        });

        isAdmin().then(value => {
            setUserIsAdmin(value);
        });
    }, []);

    React.useEffect(() => {
        applyTheme();
    }, []);

    React.useEffect(() => {
        applyTheme();
    }, [isLoading]);

    return (
        <AuthCheckProvider>
            <HelmetProvider>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Switch>
                        {/* Client Routes */}
                        <Auth.Private exact path="/" component={isUserAdmin ? Dashboard : DashboardHome} loader={Loader} pageKey="DASHBOARD" />
                        <Auth.Private exact path="/workflow" component={Dashboard} loader={Loader} pageKey="WORKFLOW" />
                        {/* <Auth.Private exact path="/dashboard-home" component={DashboardHome} loader={Loader} pageKey="DASHBOARDHOME" /> */}
                        {/* <Auth.Private exact path="/request-legal-support" component={RequestLegalSupport} loader={Loader} pageKey="REQUEST_SUPPORT" /> */}
                        <Auth.Private exact path="/new-matter" component={RequestLegalSupportDynamic} loader={Loader} pageKey="NEW_MATTER" />
                        <Auth.Private exact path="/how-to-use-lighthub" component={HowTo} loader={Loader} />
                        <Auth.Private exact path="/profile" component={Profile} loader={Loader} />
                        <Auth.Private exact path="/my-learned-friend" component={MyLearnedFriend} loader={Loader} />
                        <Auth.Private exact path="/mylearnedfriend/*" component={MyLearnedFriend} loader={Loader} />
                        <Auth.Private exact path="/mylearnedfriend" component={MyLearnedFriend} loader={Loader} />
                        <Auth.Private exact path="/settings" component={Settings} loader={Loader} pageKey="SETTINGS" />
                        <Auth.Private exact path="/contract-management" component={Contracts} loader={Loader} pageKey="CONTRACT_MANAGEMENT" />
                        <Auth.Private exact path="/integrations" component={WorkManagement} loader={Loader} pageKey="INTEGRATIONS" />
                        <Auth.Private exact path="/wrike" component={WorkManagementLiberty} loader={Loader} pageKey="WORKFLOW_MANAGEMENT" />
                        <Auth.Private exact path="/contract-storage" component={ContractStorage} loader={Loader} pageKey="CONTRACT_STORAGE" />
                        <Auth.Private exact path="/reporting" component={DataReporting} loader={Loader} pageKey="REPORTING" />
                        <Auth.Private exact path="/client-resources" component={ClientResources} loader={Loader} pageKey="RESOURCES" />
                        <Auth.Private exact path="/standard-contract-templates" component={StandardContractTemplates} loader={Loader} pageKey="RESOURCES" />
                        <Auth.Private exact path="/matter-admin" component={AdminFormBuilder} loader={Loader} pageKey="MATTER_ADMIN" />
                        <Auth.Private exact path="/matter-admin/add" component={AdminFormBuilder} loader={Loader} pageKey="MATTER_ADMIN" />
                        <Auth.Private exact path="/matter-admin/:formId" component={AdminFormBuilder} loader={Loader} pageKey="MATTER_ADMIN" />
                        <Auth.Private exact path="/signed-contract" component={SignedContracts} loader={Loader} pageKey="SIGNED_CONTRACT" />
                        <Auth.Private exact path="/templates" component={ManageTemplate} loader={Loader} pageKey="MANAGE_TEMPLATE" />
                        <Auth.Private exact path="/contract-data" component={ContactData} loader={Loader} pageKey="CONTRACT_DATA" />


                        <Route exact path="/login" component={LoginRegistration} />
                        <Route exact path="/login?subscriber=:subscriberId" component={LoginRegistration} />
                        <Route exact path="/complete-signup/:userId" component={CompleteSignup} />
                        <Route exact path="/payment/success" />
                        <Route exact path="/complete-customization" component={CompleteCustomization} />
                        <Route exact path="/email-verification" component={EmailVerification} />
                        <Route exact path="/reset-password" component={PasswordReset} />
                        <Route exact path="/set-password" component={PasswordReset} />
                        <Auth.Private exact path="/verify-otp" component={VerifyOTP} loader={Loader} />

                        {/* Contract Express Routes */}
                        <Auth.Private exact path="/contract-express/questionnaire/:contractId" component={Questionnaire} />
                        <Auth.Private exact path="/contract-express" component={List} />
                        <Auth.Private exact path="/contract-express/contract/:contractId" component={ContractDetails} />

                        {/* Admin Routes */}
                        <Auth.Private exact path="/admin/clients/:clientId" component={AdminClients} isAdminRoute pageKey="ADMIN_CLIENTS" />
                        <Auth.Private exact path="/admin/new-matter" component={AdminRequestLegalSupport} loader={Loader} isAdminRoute pageKey="ADMIN_REQUEST_LEGAL_SUPPORT" />
                        {/* <Auth.Private exact path="/admin/events-log" component={AdminEventsLog} isAdminRoute /> */}
                        {/* <Auth.Private exact path="/admin/action-history" component={AdminActionHistory} isAdminRoute /> */}
                        <Auth.Private exact path="/admin/management-center" component={AdminManagementCenter} isAdminRoute pageKey="ADMIN_MANAGEMENT_CENTER" />
                        <Auth.Private exact path="/admin/management-center/user/:userId" component={AdminManagementCenter} isAdminRoute pageKey="ADMIN_MANAGEMENT_CENTER" />
                        <Auth.Private exact path="/admin/contract-management" component={AdminContracts} isAdminRoute pageKey="ADMIN_CONTRACT_MANAGEMENT" />
                        <Auth.Private exact path="/admin/integrations" component={AdminWorkManagement} loader={Loader} pageKey="ADMIN_WORKFLOW_MANAGEMENT" isAdminRoute />
                        <Auth.Private exact path="/admin/client-resources" component={AdminClientResources} isAdminRoute pageKey="ADMIN_CLIENT_RESOURCES" />
                        <Auth.Private exact path="/admin/reporting" component={AdminReportSection} isAdminRoute pageKey="ADMIN_REPORTING" />
                        <Auth.Private exact path="/admin/category-workflow" component={AdminCategoryWorkflow} isAdminRoute pageKey="ADMIN_CATEGORY_WORKFLOW" />
                        <Auth.Private exact path="/admin/profile" component={AdminProfile} isAdminRoute />
                        <Auth.Private exact path="/admin/settings" component={AdminSettings} isAdminRoute pageKey="ADMIN_SETTINGS" />
                        <Auth.Private exact path="/admin/my-learned-friend" component={AdminLearnedFriend} isAdminRoute />
                        <Auth.Private exact path="/admin/my-learned-friend/add" component={AdminLearnedFriend} isAdminRoute />
                        <Auth.Private exact path="/admin/my-learned-friend/:contentId" component={AdminLearnedFriend} isAdminRoute />
                        <Auth.Private exact path="/admin/how-to" component={AdminHowTo} isAdminRoute pageKey="ADMIN_HOW_TO" />
                        <Auth.Private exact path="/admin/how-to/add" component={AdminHowTo} isAdminRoute pageKey="ADMIN_HOW_TO" />
                        <Auth.Private exact path="/admin/how-to/:howToId" component={AdminHowTo} isAdminRoute pageKey="ADMIN_HOW_TO" />
                        <Auth.Private exact path="/admin/subscribers" component={AdminSubscribers} isAdminRoute pageKey="ADMIN_SUBSCRIBER" />
                        <Auth.Private exact path="/admin/templates" component={AdminTemplates} isAdminRoute pageKey="ADMIN TEMPLATE" />
                        <Auth.Private exact path="/admin/templates/add" component={AdminTemplates} isAdminRoute pageKey="ADMIN TEMPLATE" />
                        <Auth.Private exact path="/admin/templates/:templateId" component={AdminTemplates} isAdminRoute pageKey="ADMIN TEMPLATE" />
                        <Auth.Private exact path="/admin/add_signed_contract" component={AdminSignedContract} isAdminRoute pageKey="ADMIN_ADD_SIGNED_CONTRACT" />
                        <Auth.Private exact path="/admin/add_signed_contract/new" component={AdminSignedContract} isAdminRoute pageKey="ADMIN_ADD_SIGNED_CONTRACT" />
                        <Auth.Private exact path="/admin/signed-contract/:contractId" component={AdminSignedContract} isAdminRoute pageKey="ADMIN_ADD_SIGNED_CONTRACT" />


                        <Auth.Private exact path="/no-permission" component={NoPermission} loader={Loader} />
                        <Auth.Private component={NotFound} loader={Loader} />
                    </Switch>
                )}
            </HelmetProvider>
            <PageLoader />
        </AuthCheckProvider>
    );
}

export default App;
