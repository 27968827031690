import React, { useEffect, useState } from 'react';
import PageHeader from './components/Header';
import AdminHeader from './components/AdminHeader';
import { Container, Header, Content, Grid } from 'rsuite';
import './styles.less';
import AdminSidebar from './components/AdminSidebar';
import authService from '../../../services/authService';
import ClientSidebar from './components/ClientSidebar';
import { applyTheme, getTheme } from '../../../services/themeService';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { actions, reducer, sliceKey } from '../../shared-redux/User/slice';
import { userSaga } from '../../shared-redux/User/saga';
import { useDispatch, useSelector } from 'react-redux';
import userService from '../../../services/userService';
import { selectUser } from '../../shared-redux/User/selectors';
import localforage from 'localforage';

const Layout = (props) => {

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userSaga });

  const dispatch = useDispatch();
  const [expand, setExpand] = useState(true);
  const [isAdmin, setIsAdmin] = useState(null);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const [page, setPage] = useState(1)

  const { notifications, notificationCount } = useSelector(selectUser);

  const [logo, setLogo] = useState('');
  const [favicon, setFavicon] = useState('');

  const handleToggle = () => {
    localforage.setItem('expand', !expand);
    setExpand(!expand)
  }

  useEffect(() => {

    userService.getCurrentUserId().then(value => {
      setUserId(value);
    });

    userService.getUser().then(value => {
      if (value)
        setUser(value);
    });

    authService.isAdmin().then((isAdmin) => {
      setIsAdmin(isAdmin);


      const queryParams = queryString.parse(props.location.search);
      if (queryParams.preview) {
        getTheme('preview-theme').then((data) => {
          if (!data) return;
          if (data.favicon) {
            setFavicon(data.favicon);
          }
          if (data.logo) {
            setLogo(data.logo);
          }
        });

        applyTheme('preview-theme');
      } else {
        getTheme('theme').then(data => {

          if (!data) return;

          if (data.favicon && !isAdmin) {
            setFavicon(data.favicon);
          }
          if (data.logo && !isAdmin) {
            setLogo(data.logo);
          }
        });

        applyTheme('theme');
      }

    });

  }, []);

  const getLastExpandState = async () => {
    const data =  localforage.getItem('expand');
    return data
  }

  useEffect(() => {
   getLastExpandState().then((res) => {
      setExpand(res)
   })
  
  },[props])

  useEffect(() => {
    if (userId) {
      dispatch({
        type: actions.fetchUserNotifications.type,
        payload: {
          userId,
          page
        },
      });
    }
  }, [userId, page]);

  const getNextPage = () => {
    setPage(page+1)
  }

  const { children, location, history } = props;
  return (
    <main>
      <Helmet
        link={[
          {
            "rel": "apple-touch-icon",
            "type": "image/png",
            "href": `${favicon}?id=${Math.random()}`
          },
          {
            "rel": "icon",
            "type": "image/png",
            "href": favicon ? `${favicon}?id=${Math.random()}` : 'favicon.ico'
          }
        ]}
      />

      <Container className="sidenav-container">

        {
          isAdmin &&
          <AdminSidebar
            location={location}
            history={history}
            expand={expand}
            logo={logo}
            user={user}
            handleToggle={handleToggle}
          />
        }
        {
          !isAdmin &&
          <ClientSidebar
            location={location}
            history={history}
            expand={expand}
            logo={logo}
            user={user}
            handleToggle={handleToggle}
          />
        }

        <Container className="page-content-wrapper">
          <Header>
            {
              !isAdmin &&
              <PageHeader
                notifications={notifications}
                notificationCount={notificationCount}
                user={user}
                setPage={getNextPage}
              />
            }
            {
              isAdmin &&
              <AdminHeader
                notifications={notifications}
                notificationCount={notificationCount}
                user={user}
                setPage={getNextPage}
              />
            }
          </Header>
          <Content className="page-content">
            <Grid fluid className="page-container">
              {children}
            </Grid>
          </Content>
        </Container>
      </Container>
    </main >
  );

}

export default Layout;