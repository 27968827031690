import axios from 'axios';
import paths from '../config/paths';
import userService from '../services/userService';

export const instanceAxios = axios.create({
  baseURL: process.env.API_BASE_URL || paths.apiBaseUrl,
  headers: {
    Accept: 'application/json',
    Platform: 'Web',
  },
});

instanceAxios.interceptors.request.use((config) => {
  return userService.getUserToken('access').then((accessToken) => {
    if (accessToken !== null) {
      config.headers.Authorization = `Bearer ${accessToken.token}`;
    }
    return Promise.resolve(config);
  }).catch((e) => {
    return Promise.reject(e);
  });
});

export class ResponseError extends Error {

  constructor(response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object} The parsed JSON from the request
 */
function parseJSON(response, completeResponse = false) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return completeResponse ? response : response.data;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url,
  options = {},
) {
  try {
    const fetchResponse = await instanceAxios.request({
      url,
      ...options,
    });
    const response = checkStatus(fetchResponse);

    return parseJSON(response);
  } catch (e) {
    const response = checkStatus(e.response);
    return parseJSON(response);
  }
}

export async function requestAndCompleteResponse(
  url,
  options = {},
) {
  try {
    const fetchResponse = await instanceAxios.request({
      url,
      ...options,
    });
    const response = checkStatus(fetchResponse);

    return parseJSON(response, true);
  } catch (e) {
    const response = checkStatus(e.response);
    return parseJSON(response);
  }
}

export async function post(
  url,
  data,
  options,
) {
  const fetchResponse = await instanceAxios.post(url, data, options);
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}
