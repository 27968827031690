import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
    isLoading: false,
    success: false,
    error: '',
    user: {}
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // register user
        registerUser() { },
        registerUserLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        registerUserSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        registerUserFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        registerUserWithAzure() { },

        // register user
        completeRegistration() { },
        completeRegistrationLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        completeRegistrationSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        completeRegistrationFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // verify email
        emailVerification() { },
        emailVerificationLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        emailVerificationSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        emailVerificationFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // login user
        loginUser() { },
        loginUserLoading(state, action) {
            state.loading = false;
            state.user = {};
            state.error = '';
        },
        loginUserSuccess(state, action) {
            state.loading = false;
            state.user = action.payload;
            state.error = '';
        },
        loginUserFailure(state, action) {
            state.loading = false;
            state.user = {};
            state.error = action.paylaod;
        },
        logoutUser() { },

        loginUserWithAzure() { },

        // Reset Password
        requestPasswordReset() { },
        requestPasswordResetLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        requestPasswordResetSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        requestPasswordResetFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // Reset Password
        resetPassword() { },
        resetPasswordLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        resetPasswordSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        resetPasswordFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // send OTP
        sendOTP() { },
        sendOTPLoading(state, action) {
            state.loading = true;
        },
        sendOTPSuccess(state, action) {
            state.loading = false;
        },
        sendOTPFailure(state, action) {
            state.loading = false;
        },

        // verify OTP
        verifyOTP() { },
        verifyOTPLoading(state, action) {
            state.loading = true;
        },
        verifyOTPSuccess(state, action) {
            state.loading = false;
        },
        verifyOTPFailure(state, action) {
            state.loading = false;
        },

        // change password
        changePassword() { },
        changePasswordLoading(state, action) {
            state.loading = true;
        },
        changePasswordSuccess(state, action) {
            state.loading = false;
        },
        changePasswordFailure(state, action) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = authSlice;
