import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { actions } from './slice';
import { request } from '../../../utils/request';
import Toast from '../../../services/toastService';
import FileDownload from 'downloadjs';
import { delayedRedirect } from '../../../utils/response';

export function* fetchHowTos(action) {
  yield put(actions.howTosLoading());
  const { page, limit } = action.payload;
  try {
    const data = yield call(
      request,
      `how-to?page=${page ? page : 1}&limit=${limit}`,
    );
    yield put(actions.howTosSuccess(data.howTos));
  } catch (e) {
    yield put(actions.howTosFailure(e.toString()));
    Toast.error('Some error occurred while getting howTos');
  }
}

export function* fetchHowTo(action) {
  yield put(actions.howToLoading());

  try {
    const data = yield call(
      request,
      `how-to/${action.payload.id}`,
    );
    yield put(actions.howToSuccess(data.howTo));
  } catch (e) {
    yield put(actions.howToFailure(e.toString()));
    Toast.error('Some error occurred while getting howTo');
  }
}

export function* downloadHowTo(action) {
  yield put(actions.downloadHowToLoading());

  try {
    const data = yield call(
      request,
      `how-to/download/${action.payload.id}`,
      {
        responseType: 'blob',
      }
    );
    yield call(FileDownload, data, action.payload.fileName, action.payload.mimeType);

    yield put(actions.downloadHowToSuccess());
  } catch (e) {
    yield put(actions.downloadHowToFailure(e.toString()));
    Toast.error('Some error occurred while getting howTo');
  }
}

export function* saveHowTo(action) {
  
  yield put(actions.saveHowToLoading());

  try {
    const data = yield call(
      request,
      `how-to`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    yield put(actions.saveHowToSuccess(data.howTo));
    
    Toast.success('Successfully saved howTo');
    yield delayedRedirect('/admin/how-to', 1000);
  } catch (e) {
    yield put(actions.saveHowToFailure(e.toString()));
    Toast.error('Some error occurred while saving howTo');
  }
}

export function* updateHowTo(action) {
  yield put(actions.updateHowToLoading());

  try {
    const data = yield call(
      request,
      `how-to/${action.payload.id}`,
      {
        method: 'patch',
        data: action.payload.data,
      },
    );
    yield put(actions.updateHowToSuccess(data.howTo));
    yield delayedRedirect('/admin/how-to', 1000);
    Toast.success('Successfully saved howTo');
  } catch (e) {
    yield put(actions.updateHowToFailure(e.toString()));
    Toast.error('Some error occurred while saving howTo');
  }
}

export function* deleteHowTo(action) {
  yield put(actions.deleteHowToLoading());

  try {
    yield call(
      request,
      `how-to/${action.payload.id}`,
      {
        method: 'delete',
      },
    );
    yield put(actions.deleteHowToSuccess({ id: action.payload.id }));
    Toast.success('Successfully deleted howTo');
  } catch (e) {
    yield put(actions.deleteHowToFailure(e.toString()));
    Toast.error('Some error occurred while deleting howTo');
  }
}

export function* howToSaga() {
  yield takeLatest(actions.fetchHowTos.type, fetchHowTos);
  yield takeLatest(actions.fetchHowTo.type, fetchHowTo);
  yield takeLatest(actions.downloadHowTo.type, downloadHowTo);
  yield takeLatest(actions.saveHowTo.type, saveHowTo);
  yield takeLatest(actions.updateHowTo.type, updateHowTo);
  yield takeLatest(actions.deleteHowTo.type, deleteHowTo);
}
