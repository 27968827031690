import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
    isLoading: false,
    success: false,
    error: '',
};

const startupSlice = createSlice({
    name: 'startup',
    initialState,
    reducers: {
        // register user
        startup(){},
        startupLoading(state, action){
            state.loading = true;
        },
        startupSuccess(state, action){
            state.loading = false;
        },
        startupFailure(state, action){
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = startupSlice;
