/**
 *
 * Asynchronously loads the component for Questionnaire
 *
 */

 import { lazyLoad } from '../../../../utils/loadable';

 export const Questionnaire = lazyLoad(
   () => import('./index'),
   module => module.Questionnaire,
 );
 