import './styles.less';
import { parseHtmlReBuildContent } from "../../../utils/stringUtility";

const TextInput = (props) => {

    const {
        inlineLabel,
        stackedLabel,
        inputOptions,
        label,
        transparent,
        rounded,
        noLabel,
        inputClassName,
        borderless,
        labelStyle
    } = props;
    return (
        <div className={`form-input-group${inlineLabel ? ' label-inline' : ''}${stackedLabel ? ' label-stacked' : ''}${typeof stackedLabel === 'undefined' && typeof inlineLabel === 'undefined' ? ' label-inline' : ''}`} >
            {!noLabel && <label style={labelStyle} className="label-inline-display"><div dangerouslySetInnerHTML={{ __html: parseHtmlReBuildContent(label) }} /> {(props.isMandatory == "true" || props.isMandatory == true) && <span style={{ color: "#F62626" }}>*</span>}</label>}
            <input className={`form-input${transparent ? ' transparent-bg' : ''}${rounded ? ' rounded' : ''}${borderless ? ' borderless' : ''}${inputClassName ? ` ${inputClassName}` : ''} ${(props.inputOptions.value && props.inputOptions.value.length) > 1 ? ' date-input--has-null ' : ''}`} {...inputOptions} />
        </div>
    );
};

TextInput.getInitialProps = (ctx) => ({
    inlineLabel: true,
    noLabel: false,
});

export default TextInput;