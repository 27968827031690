/**
 *
 * Asynchronously loads the component for CompleteCustomization
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const CompleteCustomization = lazyLoad(
   () => import('./index'),
   module => module.CompleteCustomization,
 );
 