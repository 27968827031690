/**
 *
 * Asynchronously loads the component for AdminContracts
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const AdminContracts = lazyLoad(
   () => import('./index'),
   module => module.AdminContracts,
 );
 