/**
 *
 * Asynchronously loads the component for HowTo
 *
 */

import { lazyLoad } from '../../../utils/loadable';

export const HowTo = lazyLoad(
  () => import('./index'),
  module => module.HowTo,
);
