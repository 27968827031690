import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Col, FlexboxGrid, Icon, IconButton, Panel, Row, Table } from "rsuite";
import { reducer, sliceKey, actions } from './../../shared-redux/HowTo/slice';
import { selectHowTo } from './../../shared-redux/HowTo/selectors';
import { howToSaga } from './../../shared-redux/HowTo/saga';
import { useEffect, useState } from "react";
import TablePagination from "rsuite/lib/Table/TablePagination";
import './../../styles/tables.less';
import VideoPopup from "../VideoPopup";

const AdminHowToList = (props) => {

    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: howToSaga });

    const { howTos } = useSelector(selectHowTo);

    const dispatch = useDispatch();

    const [displayLength, setDisplayLength] = useState(10);
    const [page, setPage] = useState(1);
    const [videoVisibility, setVideoVisibility] = useState();
    const [selectedVideo, setSelectedVideo] = useState();

    useEffect(() => {
        dispatch({
            type: actions.fetchHowTos.type,
            payload: { page, limit: displayLength },
        });
    }, []);

    useEffect(() => {
        dispatch({
            type: actions.fetchHowTos.type,
            payload: { page, limit: displayLength }
        });
    }, [page, displayLength]);

    const onEdit = (id) => {
        window.location.href = `/admin/how-to/${id}`;
    };

    const onnDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this howTo?')) {
            dispatch({
                type: actions.deleteHowTo.type,
                payload: { id }
            });
        }
    };

    const handleNew = () => {
        window.location.href = '/admin/how-to/add';
    };

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }

    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    const getExtension = (contentType) => {
        switch(contentType) {
            case 'PDF':
                return '.pdf';
            case 'DOC':
                return '.docx';
            default:
                break;
        }
    };

    const handleContent = (content) => {
        switch (content.contentType) {
            case 'PDF':
                dispatch({
                    type: actions.downloadHowTo.type,
                    payload: {
                        id: content.id,
                        fileName: `${content.title}${getExtension(content.contentType)}`,
                        mimeType: content.file.mime,
                    },
                });
                break;
            case 'DOC':
                dispatch({
                    type: actions.downloadHowTo.type,
                    payload: {
                        id: content.id,
                        fileName: `${content.title}${getExtension(content.contentType)}`,
                        mimeType: content.file.mime,
                    },
                });
                break;
            case 'VIDEO':
                setSelectedVideo(content.videoLink);
                showVideo();
                break;
            default:
                break;
        }
    };

    const showVideo = () => {
        setVideoVisibility(true);
    };

    const hideVideo = () => {
        setSelectedVideo(null);
        setVideoVisibility(false);
    };

    const { Column, Cell, HeaderCell } = Table;
    return (
        <Panel
            collapsible
            header={
                <FlexboxGrid className="mb-0">
                    <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                        HowTo List
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} colspan={24} md={12} className="ml-auto flex-align-right p-0">
                        <IconButton onClick={handleNew} icon={<Icon icon="plus" />} circle className="color-tertiary text-color-white" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            }
            className="page-content-panel"
            defaultExpanded
        >
            <Row>
                <Col md={24}>
                    <Table
                        affixHorizontalScrollbar
                        data={howTos.results}
                        autoHeight
                        className="admin-list-table"
                    >
                        <Column flexGrow={1} verticalAlign="middle">
                            <HeaderCell>Title</HeaderCell>
                            <Cell dataKey="title" />
                        </Column>

                        <Column flexGrow={1} verticalAlign="middle">
                            <HeaderCell>Type</HeaderCell>
                            <Cell dataKey="contentType" />
                        </Column>

                        <Column flexGrow={1} verticalAlign="middle">
                            <HeaderCell>Preview/Download</HeaderCell>
                            <Cell>
                                {
                                    rowData => {
                                        if (rowData.contentType === 'VIDEO') {
                                            return (
                                                <IconButton
                                                    onClick={() => handleContent(rowData)}
                                                    className=""
                                                    icon={<Icon icon="eye" />}
                                                />
                                            )
                                        } else {
                                            return (
                                                <IconButton
                                                    onClick={() => handleContent(rowData)}
                                                    className=""
                                                    icon={<Icon icon="file-download" />}
                                                />
                                            )
                                        }
                                    }
                                }
                            </Cell>
                        </Column>

                        <Column flexGrow={1} fixed="right" verticalAlign="middle">
                            <HeaderCell>Action</HeaderCell>

                            <Cell>
                                {
                                    rowData => {
                                        return (
                                            <span>
                                                <IconButton
                                                    onClick={() => onEdit(rowData.id)}
                                                    className="color-tertiary text-color-white mr2"
                                                    icon={<Icon icon="pencil" />} circle
                                                />
                                                <IconButton
                                                    onClick={() => onnDelete(rowData.id)}
                                                    className="color-error text-color-white"
                                                    icon={<Icon icon="trash" />}
                                                    circle
                                                />
                                            </span>
                                        );
                                    }
                                }
                            </Cell>
                        </Column>
                    </Table>
                    <TablePagination
                        lengthMenu={[
                            {
                                value: 10,
                                label: 10
                            },
                            {
                                value: 20,
                                label: 20
                            }
                        ]}
                        activePage={page}
                        displayLength={displayLength}
                        total={howTos.totalResults}
                        onChangePage={handleChangePage}
                        onChangeLength={handleChangeLength}
                    />
                </Col>
            </Row>
            <VideoPopup onClose={hideVideo} visibility={videoVisibility} video={selectedVideo} />
        </Panel>
    );
};

export default AdminHowToList;