import { InputGroup, Input, List, Whisper, Tooltip } from 'rsuite';
import './styles.less';
import IconQuestion from './../../../SVGIcons/SVG/components/IconQuestion';
import _ from 'lodash';

const SearchInput = (props) => (
  <InputGroup {...props} inside className="search-input-group">
    <Input
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
    <InputGroup.Addon className="search-input-btn">
    <Whisper speaker={<Tooltip>{props.tooltip}</Tooltip>}>
      <IconQuestion />
      </Whisper>
    </InputGroup.Addon>
    {!_.isEmpty(props.data) &&
      <div className="searched-list">
        <List>
          {
            props.data.map((search, index) => (
              <List.Item key={index}>{search.content}</List.Item>
            ))
          }
        </List>
      </div>
    }
  </InputGroup>
);

SearchInput.defaultProps = {
  onChange: () =>{},
  onBlur: () => {},
};

export default SearchInput;